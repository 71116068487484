const FaqData = [
	{
		id: 1,
		title: 'How much money can i make on WO & can i withdraw it?',
		info: 'You can earn upto 20% of the sale conversion through your social influence. Yes, you can withdraw your earnings or use the same to buy new products.',
	},
	{
		id: 2,
		title: 'Does my Earning depend on the number of followers I have?',
		info: 'No, You can earn irrespective of the number of followers you have. You can leverage your other social media channels to earn as well.',
	},
	{
		id: 3,
		title: 'Can I use the earned money to buy other products on wo ?',
		info: 'Yes. 1 coin = 1 rupee. You can buy anything on our platform with the money you have earned.',
	},
];
export default FaqData;
